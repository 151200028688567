import React, { useState } from "react";
import { graphql } from "gatsby";
import Seo from "../../components/seo";
import Container from "../../components/layouts/container";
import VideoGallery from "../../components/videoGallery";
import LayoutVideo from "../../components/layouts/layoutVideo";
import CloudVideo from "../../components/layouts/cloudvideo";
import VideoScroll from "../../components/videoScroll";

const MovingImageIndex = ({ data }) => {
  const { site, sanityMovingImageIndex } = data;

  const index = sanityMovingImageIndex;

  const [currentVideo, setCurrentVideo] = useState(index.videoArray[0]);
  const [isOpen, setIsOpen] = useState(false);

  const updateVideo = (video) => {
    console.log("Updating video to:", video); // Add this log to check the video being passed
    setCurrentVideo(video);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  //Video Gallery like Notion Reference
    {/* <VideoGallery
            list={index.videoArray}
            onTitleChange={updateVideo}
            toggleOpen={toggleOpen}
            isOpen={isOpen}
          /> */}



  return (
    <main key={index.id}>
      <LayoutVideo>
        <Seo
          title={site.title}
          description={site.description}
          keywords={site.keywords}
        />
        <Container>
        
               <VideoScroll
            list={index.videoArray}
            onTitleChange={updateVideo}
            toggleOpen={toggleOpen}
            isOpen={isOpen}
          />
          {/* <h1 className="postTitle">{index.name}</h1> */}

          <h1 className="postTitle">{index.name}</h1>
        </Container>
      </LayoutVideo>
    </main>
  );
};

export const query = graphql`
  query {
    sanityMovingImageIndex {
      id
      _id
      name
      videoArray {
        url
        title
        slug {
          current
          _key
        }
        id
        _rawBodyPortableText
        postReference {
          _key
          post {
            title
            slug {
              current
              _key
            }
            id
          }
        }
        videoThumb {
          altTitle
          url
          _key
        }
      }
    }
    site: sanitySiteSettings {
      title
      keywords
      description
      id
    }
  }
`;

export default MovingImageIndex;
