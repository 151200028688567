import React from "react";

const VideoAutoPlay = ({ videoSrcURL, videoTitle, ...props }) => {
  const isYouTube = videoSrcURL.includes("youtube");
  const isVimeo = videoSrcURL.includes("vimeo");

  let autoplayURL = videoSrcURL;
  if (isYouTube) {
    autoplayURL = `${videoSrcURL}?autoplay=1`;
  } else if (isVimeo) {
    autoplayURL = `${videoSrcURL}&autoplay=1`;
  }

  return (
    <div className="aspect-w-16 aspect-h-9">
      <iframe
        className="videoStyle"
        src={autoplayURL}
        title={videoTitle}
        allow="accelerometer; fullscreen; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

export default VideoAutoPlay;
