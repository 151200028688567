import React, { useState, useEffect } from "react";
import VideoAutoPlay from "./layouts/videoAutoPlay";
import { Link } from "gatsby";
import PortableText from "./portableText";

const VideoGallery = ({ list, onTitleChange, toggleOpen, isOpen }) => {
  const [isIndex, setIsIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(list.length - 1);
  const [nextIndex, setNextIndex] = useState(1);
  const [isBlur, setIsBlur] = useState(false);

  useEffect(() => {
    if (onTitleChange) {
      onTitleChange(list[isIndex]);
    }
    setPrevIndex((isIndex - 1 + list.length) % list.length);
    setNextIndex((isIndex + 1) % list.length);
    updateUrlWithSlug(list[isIndex].slug.current);
  }, [isIndex, onTitleChange, list]);

  const updateUrlWithSlug = (slug) => {
    const newUrl = `${window.location.pathname}#${slug}`;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  const Add = () => {
    setIsIndex((isIndex + 1) % list.length);
  };

  const Less = () => {
    setIsIndex((isIndex - 1 + list.length) % list.length);
  };

  const makeBlur = () => {
    setIsBlur(true);
  };
  const makeClear = () => {
    setIsBlur(false);
  };

  return (
    <div className="relative">
      {/* Desktop */}

      <div
        id="Top"
        className="w-full px-[80px] h-screen hidden xl:flex xl:visible flex-row my-auto justify-between items-center"
      >
        {/* Is Previous */}
        <div
          className="w-[120px] cursor-pointer"
          onClick={Less}
          onMouseEnter={makeBlur}
          onMouseLeave={makeClear}
        >
          <video
            className={
              isBlur ? "postBlur w-full h-auto" : "NopostBlur w-full h-auto"
            }
            autoPlay
            playsInline
            loop
            muted
            key={list[prevIndex].id}
          >
            <source
              src={list[prevIndex].videoThumb.url}
              type="video/mp4"
              alt={list[prevIndex].videoThumb.altTitle}
            />
          </video>
          <p className="font-caslon capitalize pt-2 group-hover:font-casloni text-center">
            Previous
          </p>
        </div>

        <div className="carr-post-wrapper group cursor-pointer flex-1 overflow-hidden flex justify-center items-center">
          <div
            className="flex transition-transform duration-500 ease-in-out"
            style={{ transform: `translateX(-${isIndex * 100}%)` }}
          >
            {list.map((item, index) => (
              <Link
                key={item.id}
                href={`/moving-image/${item.slug.current}`}
                className="flex-none w-full flex justify-center items-center"
              >
                <div className="w-full max-w-screen-lg md:mt-10 md:pb-0 md:h-screen md:flex md:justify-center lg:pt-0 xl:my-auto 2xl:h-screen 2xl:py-0 2xl:my-auto 4xl:h-5/6 flex flex-col items-center">
                  <video
                    onMouseEnter={makeBlur}
                    onMouseLeave={makeClear}
                    className={
                      isBlur ? "postBlur max-h-full" : "NopostBlur max-h-full"
                    }
                    autoPlay
                    playsInline
                    loop
                    muted
                    onClick={toggleOpen}
                  >
                    <source
                      src={item.videoThumb.url}
                      type="video/mp4"
                      alt={item.videoThumb.altTitle}
                    />
                  </video>
                  <p className="font-caslon capitalize pt-2 group-hover:font-casloni text-center">
                    {item.title}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>

        {/* Is Next */}
        <div
          className="w-[120px] cursor-pointer"
          onClick={Add}
          onMouseEnter={makeBlur}
          onMouseLeave={makeClear}
        >
          <video
            className={
              isBlur ? "postBlur w-full h-auto" : "NopostBlur w-full h-auto"
            }
            autoPlay
            playsInline
            loop
            muted
            key={list[nextIndex].id}
          >
            <source
              src={list[nextIndex].videoThumb.url}
              type="video/mp4"
              alt={list[nextIndex].videoThumb.altTitle}
            />
          </video>
          <p className="font-caslon capitalize pt-2 group-hover:font-casloni text-center">
            Next
          </p>
        </div>
      </div>

      {/* Mobile */}
      <div
        id="Top"
        className="w-full xl:hidden px-[20px] h-screen flex flex-row my-auto justify-between items-end lg:items-center"
      >
        {/* Is Previous */}
        <div className="w-[150px] mb-[100px] lg:mb-0 group cursor-pointer">
          <video
            autoPlay
            playsInline
            loop
            muted
            key={list[prevIndex].id}
            onClick={Less}
          >
            <source
              src={list[prevIndex].videoThumb.url}
              type="video/mp4"
              alt={list[prevIndex].videoThumb.altTitle}
            />
          </video>
          <p className="font-caslon capitalize pt-2 group-hover:font-casloni">
            Previous
          </p>
        </div>

        <Link
          className="carr-post-wrapper justify-center items-center group cursor-pointer"
          href={`/moving-image/${list[isIndex].slug.current}`}
        >
          <div className="mx-auto md:pb-0 md:flex md:justify-center md:w-10/12 lg:pt-0 lg:w-full xl:my-auto 2xl:py-0 2xl:my-auto flex flex-col">
            <video
              autoPlay
              playsInline
              loop
              muted
              key={list[isIndex].id}
              //   onClick={toggleOpen}
            >
              <source
                src={list[isIndex].videoThumb.url}
                type="video/mp4"
                alt={list[isIndex].videoThumb.altTitle}
              />
            </video>
            <p className="font-caslon capitalize pt-2 group-hover:font-casloni">
              {list[isIndex].title}
            </p>
          </div>
        </Link>

        {/* Is Next */}
        <div className="w-[150px] mb-[100px] lg:mb-0 group" onClick={Add}>
          <video autoPlay playsInline loop muted key={list[nextIndex].id}>
            <source
              src={list[nextIndex].videoThumb.url}
              type="video/mp4"
              alt={list[nextIndex].videoThumb.altTitle}
            />
          </video>
          <p className="font-caslon capitalize pt-2 group-hover:font-casloni">
            Next
          </p>
        </div>
      </div>

      {/* Autoplay this video when isOpen */}
      {/* {isOpen && (
        <div className="w-full z-10 top-0 absolute min-h-screen flex flex-col items-center justify-center bg-black overflow-y-auto">
          <div className="h-screen my-auto flex items-center justify-center">
            <div className="z-20 w-full lg:w-[1200px]">
              <VideoAutoPlay
                videoSrcURL={list[isIndex].url}
                videoTitle={list[isIndex].title}
                className="w-[800px] h-auto"
                key={list[isIndex].id}
              />
            </div>
          </div>

          <div
            className="relative w-full text-center items-center justify-center font-helve uppercase text-white mt-5 px-4 pb-[80px]"
            key={list[isIndex]._rawBodyPortableText._key}
          >
            <PortableText blocks={list[isIndex]._rawBodyPortableText} />
          </div>

          <div className="flex flex-row w-full mt-10 px-4  lg:px-[80px] justify-between fixed bottom-4">
            <Link
              className="text-white font-caslon hover:font-casloni"
              to={`/${list[isIndex].postReference.post.slug.current}`}
            >
              See full project
            </Link>
            <div
              className="text-white font-caslon cursor-pointer hover:font-casloni"
              onClick={toggleOpen}
            >
              Close
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default VideoGallery;
